import React, { useEffect, useRef } from "react";
import AppStoreBadge from "../image/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import "./Projects.css";

const ProjectSample = ({ project, index }) => {
  const sectionRef = useRef(null);
  const sampleRef = useRef(null);

  const animatedSections = new Set();

  useEffect(() => {
    const section = sectionRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !animatedSections.has(section)) {
          // Add the animation class based on the index
          section.classList.add(
            index % 2 === 0 ? "project-fade-out" : "project-fade-in"
          );

          // Mark this section as animated
          animatedSections.add(section);

          // Disconnect the observer for this section once animated
          observer.unobserve(entry.target);
        }
      });
    });

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.disconnect();
      }
    };
  }, [index]);

  // useEffect(() => {
  //   const section = sampleRef.current;

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting && !animatedSections.has(section)) {
  //         // Add the animation class based on the index
  //         section.classList.add(
  //           (index % 2 === 0 ? ["project-slide-in-right,visible-element"] : ["project-slide-in-left,visible-element"])
  //         );

  //         // Mark this section as animated
  //         animatedSections.add(section);

  //         // Disconnect the observer for this section once animated
  //         observer.unobserve(entry.target);
  //       }
  //     });
  //   });

  //   if (section) {
  //     observer.observe(section);
  //   }

  //   return () => {
  //     if (section) {
  //       observer.disconnect();
  //     }
  //   };
  // }, [index]);
  useEffect(() => {
    const section = sampleRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !animatedSections.has(section)) {
          // Determine the animation class based on the index
          const animationClass =
            index % 2 === 0
              ? "project-slide-in-right"
              : "project-slide-in-left";

          // Add the animation class and visible-element class
          section.classList.add(animationClass, "visible-element");

          // Mark this section as animated
          animatedSections.add(section);

          // Disconnect the observer for this section once animated
          observer.unobserve(entry.target);
        }
      });
    });

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.disconnect();
      }
    };
  }, [index]);


  return (
    <div ref={sampleRef} className="project-sample-container hidden-element">
      <section className="sss">
        <div className="project-details">
          <a
            href={project.projectUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="project-link"
          >
            <div className="project-image-container">
              <img
                ref={sectionRef}
                src={project.imageUrl}
                alt={project.title}
                className="project-image"
              />
              <div className="overlay">
                <div className="overlay-text">View Project</div>
              </div>
            </div>
          </a>
          <div className="details-box">
            <h2 className="project-title">{project.title}</h2>
            {(project.title ===
              "XP1: Your Ultimate Expense Tracking Companion" && (
              <a
                href="https://apps.apple.com/us/app/xp1/id6470176329"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={AppStoreBadge}
                  alt="Download on the App Store"
                  className="app-store-badge"
                />
              </a>
            )) ||
              (project.title === "Golista!" && (
                <a
                  href="https://apps.apple.com/us/app/golista/id6450496916"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppStoreBadge}
                    alt="Download on the App Store"
                    className="app-store-badge"
                  />
                </a>
              ))}
            <p className="project-description">{project.description}</p>
          </div>
        </div>

        <div className="project-details">
          <div className="details-box">
            <p className="subTitle">Features</p>
            {/* <div className="feature-container"> */}
            {project.features &&
              project.features.map((feature, index) => (
                <p
                  key={index}
                  className="feature"
                  dangerouslySetInnerHTML={{ __html: feature }}
                ></p>
              ))}
          </div>
          <div className="details-box">
            <p className="subTitle">Technical Implementation</p>
            {/* <div className="feature-container"> */}
            {project.technical &&
              project.technical.map((technical, index) => (
                <p
                  key={index}
                  className="feature"
                  dangerouslySetInnerHTML={{ __html: technical }}
                ></p>
              ))}
          </div>
          {/* </div> */}
        </div>
      </section>
    </div>
  );
};

export default ProjectSample;
