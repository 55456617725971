import React, { useEffect } from "react";

const FormspreeButton = () => {
  const YOUR_FORM_ID = "mzbnlebb";
  useEffect(() => {
    // Add the Formspree script
    const script = document.createElement("script");
    script.src = "https://formspree.io/js/formbutton-v1.min.js";
    script.defer = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize Formspree after script loads
    script.onload = () => {
      window.formbutton =
        window.formbutton ||
        function () {
          const formbutton = (formbutton.q = formbutton.q || []).push(
            arguments
          );
        };
      window.formbutton("create", {
        title: "👋🏽Let's Connect!",
        action: `https://formspree.io/${YOUR_FORM_ID}`, // Replace YOUR_FORM_ID with your actual Form ID
        fields: [
          {
            name: "email",
            type: "email",
            label: "Your Email",
            required: true,
          },
          {
            name: "Message",
            label: "Need website? Consultation?",
            type: "textarea",
          },
          {
            type: "submit",
          },
        ],
        styles: {
          fontFamily: '"Poppins Light", sans-serif',
          button: {
            background: "transparent",
          },
          title: {
            background: "#0e0e0e",
            letterSpacing: "0.05em",
            fontSize: "2.2vh",
            //   opacity: "90%",
            textTransform: "uppercase",
          },
        },
      });
    };

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component doesn't render anything directly
};

export default FormspreeButton;
