import React, { useEffect, useMemo, useState } from "react";

import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import NaviBar from "./components/navbar/NaviBar";
import Projects from "./screens/Projects";
import About from "./screens/About";
import Contact from "./screens/Contact";

import ChatButtom from './components/contact/ContactForm'

import { Analytics } from "@vercel/analytics/react";

const App = () => {
  const [init, setInit] = useState(false);

  const options = useMemo(
    () => ({
      background: {
        color: { value: "transparent" },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: { enable: true, mode: "push" },
          onHover: { enable: true, mode: "repulse" },
        },
        modes: {
          push: { quantity: 4 },
          repulse: { distance: 200, duration: 0.4 },
        },
      },
      particles: {
        color: { value: "#cfaf12" },
        links: {
          color: "#c3a619",
          distance: 150,
          enable: true,
          opacity: 0.6,
          width: 1.6,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: { default: "bounce" },
          speed: .7,
        },
        number: {
          density: { enable: true },
          value: 50,
        },
        opacity: { value: 0.9 },
        shape: { type: "circle" },
        size: { value: { min: 1, max: 4 } },
      },
      detectRetina: true,
    }),
    []
  );

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine); // Initialize particles engine with a lightweight setup
    }).then(() => setInit(true));
  }, []);

  return (
    <>
      <Router>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap"
          rel="stylesheet"
        ></link>
        <div className="Heading">
          <header className="App-header">
            <NaviBar />
          </header>
          <div className="Bod">
            <ChatButtom />
            {init && <Particles id="tsparticles" options={options} />}
            <Routes>
              <Route path="/" element={<Projects />} />
              <Route path="/Projects" element={<Projects />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/About" element={<About />} />
            </Routes>
          </div>
        </div>
      </Router>
      <Analytics />
    </>
  );
};

export default App;